<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotTwinsManage' }">孪生体管理</el-breadcrumb-item>
          <el-breadcrumb-item>孪生体详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" label-width="100px">
          <!-- 孪生体信息 -->
          <div class="form-title">孪生体信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="孪生体分类:">{{row.park}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="孪生体名称:">{{row.digitalTwinName}}</el-form-item>
            </el-col>
          </el-row>

          <!-- 实体设备信息 -->
          <div class="form-title">实体设备信息</div>
          <el-row :gutter="20" v-if="twinData">
            <el-col :span="12">
              <el-form-item label="部件名称:">{{twinData.deviceParts}}</el-form-item>
            </el-col>
          </el-row>
          <div v-else class="unbind">未绑定设备</div>

          <!-- 已选择的设备 -->
          <div v-if="tableData.length>0">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="waterworks" label="水厂">
                <template slot-scope="scope">
                  {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="position" label="泵房编号">
                <template slot-scope="scope">
                  {{ scope.row.position?scope.row.position:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
              <el-table-column prop="eid" label="设备号"></el-table-column>
              <el-table-column prop="deviceTypeCode" label="设备类型">
                <template slot-scope="scope">
                  <span v-show="scope.row.deviceTypeCode=='Direct'">直连设备</span>
                  <span v-show="scope.row.deviceTypeCode=='GatewaySub'">网关子设备</span>
                  <span v-show="scope.row.deviceTypeCode=='Gateway'">网关</span>
                </template>
              </el-table-column>
              <el-table-column prop="model" label="设备型号">
                <template slot-scope="scope">
                  {{ scope.row.model?scope.row.model:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="cardNumber" label="物联网卡号">
                <template slot-scope="scope">
                  {{ scope.row.cardNumber?scope.row.cardNumber:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentBrandCode" label="设备品牌"></el-table-column>
            </el-table>
          </div>

          <!-- 孪生体属性 -->
          <div style="display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title" style="margin-top: 20px;">孪生体属性</div>
          </div>
          <div v-if="twinData">
            <div class="custom-attr" v-for="(item, index) in twinData.attributeValues" :key="index">
              <div class="custom-head">
                <div class="head-title"><span>默认属性 - {{index}}</span></div>
              </div>
              <!-- 多个数据类型 -->
              <div v-for="(dItem, dIndex) in item" :key="dIndex" class="data-content">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="数据类型:">{{dItem.dataType}}</el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="点表值类型:" v-if="dItem.dataType=='Bool'">
                      <span v-show="dItem.boolData.formulaType=='default'">默认值</span>
                      <span v-show="dItem.boolData.formulaType=='formula'">公式</span>
                    </el-form-item>
                    <el-form-item label="点表值类型:" v-if="dItem.dataType=='Real'">
                      <span v-show="dItem.realData.formulaType=='default'">默认值</span>
                      <span v-show="dItem.realData.formulaType=='formula'">公式</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div v-if="dItem.dataType=='Bool'">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="单位:">{{dItem.boolData.unit?dItem.boolData.unit:'无'}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="描述:">{{dItem.boolData.tagDescribe?dItem.boolData.tagDescribe:'无'}}</el-form-item>
                    </el-col>
                  </el-row>
                  <div v-if="dItem.boolData.formulaType=='formula'">
                    <div class="formula-title">
                      <div class="formula-title-left">配置公式</div>
                    </div>
                    <el-row :gutter="20" style="margin: 30px 0px 0px 30px;">
                      <el-col :span="12">
                        <el-form-item label="公式" label-width="70px">
                          <span>{{ dItem.boolData.formula }}</span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="点表:">{{dItem.boolData.tag?dItem.boolData.tag:'无'}}</el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- Tag说明 -->
                  <div class="tag-explain">
                    <span class="tag-explain-title">Tag说明</span>
                  </div>
                  <div v-for="(teItem, teIndex) in dItem.boolData.values" :key="teIndex">
                    <el-row :gutter="20">
                      <el-col :span="10">
                        <el-form-item label="编码:">{{teIndex}}</el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <el-form-item label="含义:">{{teItem}}</el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-if="dItem.dataType=='Real'">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="单位:">{{dItem.realData.unit?dItem.realData.unit:'无'}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="描述:">{{dItem.realData.tagDescribe?dItem.realData.tagDescribe:'无'}}</el-form-item>
                    </el-col>
                  </el-row>
                  <div v-if="dItem.realData.formulaType=='formula'">
                    <div class="formula-title">
                      <div class="formula-title-left">配置公式</div>
                    </div>
                    <el-row :gutter="20" style="margin: 30px 0px 0px 30px;">
                      <el-col :span="12">
                        <el-form-item label="公式" label-width="70px">
                          <span>{{ dItem.realData.formula }}</span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="点表:">{{dItem.realData.tag?dItem.realData.tag:'无'}}</el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="unbind">未绑定设备</div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotTwinsManage')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      row: {},
      twinData: {},
      form: {},
      tableData: [],//实体设备数组
    };
  },
  methods: {
    // 查询孪生体绑定详情
    loadTwinsData() {
      this.loading = true;
      this.$ajax.post("iotTwinsDetail", {}, { 
        digitalTwinId: this.row.digitalTwinId
      }).then(res=>{
        if(res.data) {
          this.loadIotDevcieDetail(res.data.eid)
        }
        this.twinData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false;
      })
    },
    // 查询iot设备详情
    loadIotDevcieDetail(eid) {
      this.$ajax.post("iotEquipmentDetail", {}, { 
        eid: eid
      }).then(res=>{
        this.tableData.push(res.data)
      })
    }
  },
  created() {
    if (this.$route.params.row == null) {
      this.$router.push("/iotTwinsManage");
    } else {
      this.row = this.$route.params.row
    }
    this.loadTwinsData()
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.custom-attr {
  border: 1px solid #ebeef5;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
}
.custom-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .head-title {
    padding: 0 10px;
    height: 40px;
    background:#1494fb;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 40px;
    text-align: center;
    color: #FFF;
  }
}
.head-close {
  width: 25px;
  height: 25px;
  background: #ff7979;
  border-radius: 5px;
  text-align: center;
  line-height: 25px;
  color: #FFF;
  font-size: 22px;
}
.head-close:hover {
  cursor: pointer;
  opacity: .8;
}
.data-content {
  background: #f9f9fa;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin: 10px;
  padding-top: 20px;
  padding-right: 10px;
}
.tag-explain {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:20px 0px;
}
.tag-explain-title {
  font-size:14px;
  margin-left:40px;
  font-weight:bold;
}
.unbind {
  font-size: 14px;
  padding-left: 15px;
}
.formula-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .formula-title-left::before {
        content: '';
        border-left: 4px solid #409EFF;
        padding-left: 10px;
    }
}
</style>